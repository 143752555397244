import {
  ConsumptionApiReturn,
  EnergyChartDataByPeriod,
  EnergyDashboard,
  EnergyPowerApiResponse,
  EnergyPro,
} from '@energybox/react-ui-library/dist/types';
import {
  normalizeTSEnergyByEquipmentId,
  normalizeTSEnergyDashboardBySiteId,
  normalizeTSEquipmentPowerBySiteId,
  normalizeTSSiteEnergyConsumption,
} from '@energybox/react-ui-library/dist/utils';
import { normalizeTSEnergyByResourceId } from '@energybox/react-ui-library/dist/utils/timeSeries';
import assoc from 'ramda/src/assoc';
import assocPath from 'ramda/src/assocPath';
import dissocPath from 'ramda/src/dissocPath';
import pipe from 'ramda/src/pipe';
import { Actions as EnergyActions } from '../actions/energy';
import { Actions as TSEnergyActions } from '../actions/energyTS';

export type Energy = {
  energyBySiteId: EnergyByResourceIdResponse;
  energyByEquipmentId: EnergyByResourceIdResponse;
  energyByEquipmentGroupId: EnergyByResourceIdResponse;
  energyByEquipmentTypeId: EnergyByResourceIdResponse;
  energyByFeatureNotificationId: EnergyByFeatureNotificationId;
  equipmentPowerBySiteId: EquipmentPowerBySiteId;
  energySensorsByEquipmentId: EnergySensorsByEquipmentId;
  isLoading: boolean;
  isLoadingByResourceId: IsLoadingByResourceId;
  energyDashboardBySiteId: EnergyDashboardBySiteId;
  co2EmissionBySiteId: EnergySiteCO2EmissionBySiteId;
  isEnergyDashboardLoadingBySiteId: IsEnergyDashboardLoadingBySiteId;
  totalConsumptionBySiteIdChart: TotalConsumptionBySiteIdChart;
  isLoadingAllEnergyPros: boolean;
  energyProBySiteId: EnergyProBySiteId;
};

export type IsLoadingByResourceId = {
  [resourceId: string]: boolean;
};

export type EnergyDashboardBySiteId = {
  [siteId: string]: EnergyDashboardStatus;
};

export type EnergySiteCO2EmissionBySiteId = {
  [siteId: string]: EnergySiteCO2Emission;
};

export type EnergySiteCO2Emission = {
  title: string;
  ttl_kwh: number;
  co2_emission: number;
  co2_factor: number;
  region: string;
  type: string;
};

export type EnergyDashboardStatus = {
  data?: EnergyDashboard;
  co2Factor: number;
  isLoading: boolean;
};

export type EnergyByResourceIdResponse = {
  [equipmentId: string]: EnergyPowerApiResponse;
};

export type EnergySensorsByEquipmentId = {
  [equipmentId: string]: string[];
};

export type EquipmentPowerBySiteId = {
  [siteId: string]: RecentPowerByEquipmentId | {};
};

export type RecentPowerByEquipmentId = {
  [equipmentId: string]: ConsumptionApiReturn;
};

export type EnergyByFeatureNotificationId = {
  [notificationId: string]: EnergyPowerApiResponse;
};

export type IsEnergyDashboardLoadingBySiteId = {
  [siteId: string]: boolean;
};

export type TotalConsumptionBySiteIdChart = {
  [siteId: string]: {
    byPeriod?: EnergyChartDataByPeriod;
  };
};

export type EnergyProBySiteId = {
  [siteId: string]: EnergyProStatus;
};

export type EnergyProStatus = {
  hasEnergyPro: boolean;
  energyPro?: EnergyPro;
  isLoading: boolean;
  data?: any;
};

const initialState: Energy = {
  energyBySiteId: {},
  energyByEquipmentId: {},
  energyByEquipmentGroupId: {},
  energyByEquipmentTypeId: {},
  energyByFeatureNotificationId: {},
  equipmentPowerBySiteId: {},
  energySensorsByEquipmentId: {},
  isLoading: false,
  isLoadingByResourceId: {},
  energyDashboardBySiteId: {},
  co2EmissionBySiteId: {},
  isEnergyDashboardLoadingBySiteId: {},
  totalConsumptionBySiteIdChart: {},
  isLoadingAllEnergyPros: false,
  energyProBySiteId: {},
};

export default (state: Energy = initialState, action: any) => {
  switch (action.type) {
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          action.notificationId
            ? ['energyByFeatureNotificationId', action.notificationId]
            : ['energyByEquipmentId', action.equipmentId],
          action.payload
        ),
        assocPath(
          [
            'isLoadingByResourceId',
            action.notificationId || action.equipmentId,
          ],
          false
        )
      )(state);

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_LOADING:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_LOADING:
      return pipe(
        assocPath(
          [
            'isLoadingByResourceId',
            action.notificationId || action.equipmentId,
          ],
          true
        ),
        assocPath(
          action.notificationId
            ? [
                'energyByFeatureNotificationId',
                action.notificationId,
                'readings',
              ]
            : ['energyByEquipmentId', action.equipmentId, 'readings'],
          []
        )
      )(state);

    //TODO: error handling
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_ERROR:
    case EnergyActions.ENERGY_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.notificationId || action.equipmentId],
        false,
        state
      );

    case EnergyActions.GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(['equipmentPowerBySiteId', action.siteId], action.payload),
        assoc('isLoading', false)
      )(state);

    case TSEnergyActions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING:
    case EnergyActions.GET_EQUIPMENT_POWER_BY_SITE_ID_LOADING:
      return assoc('isLoading', true, state);

    case TSEnergyActions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR:
      return pipe(
        assocPath(['equipmentPowerBySiteId', action.siteId], {}),
        assoc('isLoading', false)
      )(state);
    case EnergyActions.GET_EQUIPMENT_POWER_BY_SITE_ID_ERROR:
      return assoc('isLoading', false, state);

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energySensorsByEquipmentId', action.equipmentId],
          action.payload
        ),
        assocPath(['isLoadingByResourceId', action.equipmentId], false)
      )(state);

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_LOADING:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        true,
        state
      );

    case EnergyActions.GET_ENERGY_SENSORS_BY_EQUIPMENT_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case EnergyActions.GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'data'],
          action.payload
        ),
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING:
    case EnergyActions.GET_ENERGY_DASHBOARD_BY_SITE_ID_LOADING:
      return assocPath(
        ['energyDashboardBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case TSEnergyActions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR:
    case EnergyActions.GET_ENERGY_DASHBOARD_BY_SITE_ID_ERROR:
      return assocPath(
        ['energyDashboardBySiteId', action.siteId, 'isLoading'],
        false,
        state
      );

    case EnergyActions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_LOADING:
      return assocPath(
        ['co2EmissionBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case EnergyActions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['co2EmissionBySiteId', action.siteId, 'data'],
          action.payload.data.by_site[action.siteId]
        ),
        assocPath(['co2EmissionBySiteId', action.siteId, 'isLoading'], false)
      )(state);

    case EnergyActions.GET_ENERGY_CO2_EMISSION_BY_SITE_ID_ERROR:
      return assocPath(
        ['co2EmissionBySiteId', action.siteId, 'isLoading'],
        false,
        state
      );

    case EnergyActions.GET_SITE_ENERGY_CONSUMPTION_SUCCESS:
      return pipe(
        assocPath(
          [
            'totalConsumptionBySiteIdChart',
            action.siteId,
            'byPeriod',
            action.key,
            'value',
          ],
          action.payload
        ),
        assocPath(
          [
            'totalConsumptionBySiteIdChart',
            action.siteId,
            'byPeriod',
            action.key,
            'isLoading',
          ],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_SITE_ENERGY_CONSUMPTION_LOADING:
    case EnergyActions.GET_SITE_ENERGY_CONSUMPTION_LOADING:
      return pipe(
        dissocPath(['totalConsumptionBySiteIdChart', action.siteId]),
        assocPath(
          [
            'totalConsumptionBySiteIdChart',
            action.siteId,
            'byPeriod',
            action.key,
            'isLoading',
          ],
          true
        )
      )(state);

    case TSEnergyActions.TS_GET_SITE_ENERGY_CONSUMPTION_ERROR:
    case EnergyActions.GET_SITE_ENERGY_CONSUMPTION_ERROR:
      return assocPath(
        [
          'totalConsumptionBySiteIdChart',
          action.siteId,
          'byPeriod',
          action.key,
          'isLoading',
        ],
        false,
        state
      );

    case EnergyActions.GET_ENERGYPROS_SUCCESS:
      const energyPros: EnergyPro[] = action.payload;
      const energyProBySiteId = createEnergyProByIdDict(energyPros);

      return pipe(
        assocPath(['isLoadingAllEnergyPros'], false),
        assocPath(['energyProBySiteId'], energyProBySiteId)
      )(state);

    case EnergyActions.GET_ENERGYPROS_LOADING:
      return assocPath(['isLoadingAllEnergyPros'], true, state);

    case EnergyActions.GET_ENERGYPROS_ERROR:
      return pipe(assocPath(['isLoadingAllEnergyPros'], false))(state);
    case EnergyActions.GET_ENERGYPRO_BY_SITE_ID_SUCCESS:
      const energyPro = action.payload.length ? action.payload[0] : null;
      return pipe(
        assocPath(['energyProBySiteId', action.siteId, 'isLoading'], false),
        assocPath(['energyProBySiteId', action.siteId, 'data'], energyPro)
      )(state);

    case EnergyActions.GET_ENERGYPRO_BY_SITE_ID_LOADING:
      return assocPath(
        ['energyProBySiteId', action.siteId, 'isLoading'],
        true,
        state
      );

    case EnergyActions.GET_ENERGYPRO_BY_SITE_ID_ERROR:
      return pipe(
        assocPath(['energyProBySiteId', action.siteId, 'isLoading'], false),
        assocPath(['energyProBySiteId', action.siteId, 'data'], null)
      )(state);

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_ID_SUCCESS:
      return pipe(
        assocPath(
          action.notificationId
            ? ['energyByFeatureNotificationId', action.notificationId]
            : ['energyByEquipmentId', action.equipmentId],
          normalizeTSEnergyByEquipmentId(action.payload)
        ),
        assocPath(
          [
            'isLoadingByResourceId',
            action.notificationId || action.equipmentId,
          ],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_DASHBOARD_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'data'],
          normalizeTSEnergyDashboardBySiteId(action.payload)
        ),
        assocPath(
          ['energyDashboardBySiteId', action.siteId, 'isLoading'],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_EQUIPMENT_POWER_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['equipmentPowerBySiteId', action.siteId],
          normalizeTSEquipmentPowerBySiteId(action.payload)
        ),
        assoc('isLoading', false)
      )(state);

    case TSEnergyActions.TS_GET_SITE_ENERGY_CONSUMPTION_SUCCESS:
      return pipe(
        assocPath(
          [
            'totalConsumptionBySiteIdChart',
            action.siteId,
            'byPeriod',
            action.key,
            'value',
          ],
          normalizeTSSiteEnergyConsumption(action.payload)
        ),
        assocPath(
          [
            'totalConsumptionBySiteIdChart',
            action.siteId,
            'byPeriod',
            action.key,
            'isLoading',
          ],
          false
        )
      )(state);

    case TSEnergyActions.TS_GET_ENERGY_BY_SITE_ID_LOADING:
      return pipe(
        assocPath(['isLoadingByResourceId', action.siteId], true),
        assocPath(
          action.notificationId
            ? [
                'energyByFeatureNotificationId',
                action.notificationId,
                'readings',
              ]
            : ['energyByEquipmentId', action.equipmentId, 'readings'],
          []
        )
      )(state);
    case TSEnergyActions.TS_GET_ENERGY_BY_SITE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyBySiteId', action.siteId],
          normalizeTSEnergyByResourceId(action.payload)
        ),
        assocPath(['isLoadingByResourceId', action.siteId], false)
      )(state);
    case TSEnergyActions.TS_GET_ENERGY_BY_SITE_ID_ERROR:
      return assocPath(['isLoadingByResourceId', action.siteId], false, state);

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyByEquipmentGroupId', action.groupId],
          normalizeTSEnergyByResourceId(action.payload)
        ),
        assocPath(['isLoadingByResourceId', action.groupId], false)
      )(state);
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_LOADING:
      return assocPath(['isLoadingByResourceId', action.groupId], true)(state);
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_GROUP_ID_ERROR:
      return assocPath(
        ['isLoadingByResourceId', action.equipmentId],
        false,
        state
      );

    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_SUCCESS:
      return pipe(
        assocPath(
          ['energyByEquipmentTypeId', action.typeId],
          normalizeTSEnergyByResourceId(action.payload)
        ),
        assocPath(['isLoadingByResourceId', action.typeId], false)
      )(state);
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_LOADING:
      return assocPath(['isLoadingByResourceId', action.typeId], true)(state);
    case TSEnergyActions.TS_ENERGY_BY_EQUIPMENT_TYPE_ID_ERROR:
      return assocPath(['isLoadingByResourceId', action.typeId], false, state);

    default:
      return state;
  }
};

const createEnergyProByIdDict = (energyPros: EnergyPro[]) => {
  const energyProsBySiteId = {};
  energyPros.forEach((energyPro) => {
    if (energyPro.networkGroup !== undefined) {
      energyProsBySiteId[energyPro.networkGroup.siteId] = { data: energyPro };
    }
  });
  return energyProsBySiteId;
};
