import {
  DateFilter,
  LongExtraLongSkeletonCell,
  ShortenedSpan,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import {
  Columns,
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  FilterOption,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import { useEffect, useState } from 'react';
import SeeAllPage from '../../../../components/views/SeeAllPage';
import { useOrganizationEUIReport } from '../../../../hooks/useAnalytics';
import { useSearchFilter } from '../../../../hooks/useFilters';
import * as Routes from '../../../../routes';
import * as R from 'ramda';
import { mapValues } from '../../../../util';
import { Link } from 'react-router-dom';
import Button from '../../../../components/ui/Button';
import { Line, LineChart, ResponsiveContainer, Tooltip } from 'recharts';
import {
  startOrganizationCO2Report,
  startOrganizationEnergySummary,
  startOrganizationEUIReport,
} from '../../../../actions/analytics';
import { useDispatch, useSelector } from 'react-redux';
import useAppLocale from '../../../../hooks/useAppLocale';
import { useOrganizationId } from '../../../../hooks/useCurrentUser';
import { endOfYear, startOfYear } from 'date-fns';
import useSiteFilter from '../../../../hooks/useSiteFilter';
import SiteFilter from '../../../../components/Filters/SiteFilter';
import { formatNumber } from '@energybox/react-ui-library/dist/utils/number';
import styles from './AnnualUsageIntensity.module.css';
import { DA_REPORT_AVAILABLE_DATE } from '../../constants';
import usePaginationFilter from '../../../../hooks/usePaginationFilter';
import { ApplicationState } from '../../../../reducers';

const KWH_PER_M2_TARGET = 500;

const AnnualUsageIntensity: React.FC = () => {
  const dispatch = useDispatch();
  const locale = useAppLocale();
  const orgId = useOrganizationId();
  const { selectedSiteFilters } = useSiteFilter();
  const now = new Date();
  const month = now.getMonth() + 1;
  const year = month >= 2 ? now.getFullYear() : now.getFullYear() - 1;
  const [selectedYear, setSelectedYear] = useState<number>(year);
  const [selectedFilterOption, setSelectedFilterOption] =
    useState<FilterOption>({
      title: String(selectedYear),
      fromDate: startOfYear(now),
      toDate: endOfYear(now),
    });
  const disableAfterOption =
    now.getMonth() > 0
      ? now.getFullYear() + 1 + '-' + now.getMonth()
      : now.getFullYear() - 1 + '-' + now.getMonth();

  const euiReport = useOrganizationEUIReport(selectedYear);
  const {
    org_eui_existing = 0,
    org_eui_est = 0,
    site_eui,
    repo_year,
  } = euiReport.data || {};
  const { isLoading } = euiReport;

  if (repo_year && repo_year !== selectedYear) {
    setSelectedYear(repo_year);
  }

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  const updateYearParam = (e) => {
    setSelectedYear(e);
    const now = new Date();
    const month = e === now.getFullYear() ? now.getMonth() + 1 : 12;
    setSelectedFilterOption({
      title: e,
      fromDate: startOfYear(new Date(`${e}-${month}-01`)),
      toDate: endOfYear(new Date(`${e}-${month}-01`)),
    });
    dispatch(
      startOrganizationEUIReport(
        orgId || 0,
        `${e}-${month}-01`,
        KWH_PER_M2_TARGET,
        siteIdsStr
      )
    );
    dispatch(
      startOrganizationCO2Report(orgId || 0, `${e}-${month}-01`, siteIdsStr)
    );
    dispatch(
      startOrganizationEnergySummary(orgId || 0, `${e}-${month}-01`, siteIdsStr)
    );
  };

  const updateSelectedFilterOption = (e: FilterOption) => {
    updateYearParam(e.fromDate.getFullYear());
  };

  useEffect(() => {
    if (repo_year && repo_year !== selectedYear) {
      setSelectedYear(selectedYear);
      const now = new Date();
      const month = repo_year === now.getFullYear() ? now.getMonth() + 1 : 12;
      setSelectedFilterOption({
        title: selectedYear.toString(),
        fromDate: startOfYear(new Date(`${selectedYear}-${month}-01`)),
        toDate: endOfYear(new Date(`${selectedYear}-${month}-01`)),
      });
    }
  }, [selectedYear]);

  useEffect(() => {
    if (repo_year && repo_year !== selectedYear) {
      setSelectedYear(repo_year);
      const now = new Date();
      const month = repo_year === now.getFullYear() ? now.getMonth() + 1 : 12;
      setSelectedFilterOption({
        title: repo_year.toString(),
        fromDate: startOfYear(new Date(`${repo_year}-${month}-01`)),
        toDate: endOfYear(new Date(`${repo_year}-${month}-01`)),
      });
    }
  }, []);

  const MONTH_SHORTEN = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const columns: Columns<typeof site_eui>[] = [
    {
      header: 'Site Name',
      width: '20%',
      cellContent: ({ site_title, siteId }) => (
        <div style={{ padding: '5px' }}>
          <Link to={`/sites/${siteId}/dashboard`}>
            <ShortenedSpan
              content={site_title}
              maxStringLength={45}
              arrowDirection={'bottom'}
            />
          </Link>
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_title',
        ]);
      },
    },
    {
      header: 'EUI (YTD)',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_eui_existing }) => (
        <div style={{ padding: '5px', lineHeight: '1.5rem' }}>
          {formatNumber(site_eui_existing || 0, 1)}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_eui_existing',
        ]);
      },
    },
    {
      header: 'Estimated EUI',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ site_eui_est }) => {
        const euiEst = site_eui_est.eui_est || 0;
        const style =
          euiEst > KWH_PER_M2_TARGET
            ? {
                backgroundColor: 'rgba(254, 0, 129, 0.15)',
                color: '#FE0081',
                padding: '5px',
                lineHeight: '1.5rem',
              }
            : {
                backgroundColor: 'rgba(42, 221, 208, 0.15)',
                color: '#52C662',
                padding: '5px',
                lineHeight: '1.5rem',
              };
        return <div style={style}>{formatNumber(euiEst || 0, 1)}</div>;
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'site_eui_est',
          'eui_est',
        ]);
      },
    },
    {
      header: 'Trend',
      width: '10%',
      align: 'center',
      cellStyle: { height: '50px' },
      // rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const keys = R.keys(monthly_eui);
        const values = R.values(monthly_eui);
        const trendData = values.map((k, i) => {
          return {
            name: keys[i],
            value: k instanceof Object ? k.value : k,
          };
        });
        return (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={150} height={50} data={trendData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#00a1af"
                strokeWidth={2}
                dot={{ fill: '#00a1af', strokeWidth: 2, r: 0 }}
                isAnimationActive={false}
              />
              <Tooltip
                formatter={(value, name, props) => {
                  return [`${formatNumber(value, 1)} kWh/m²`];
                }}
                labelFormatter={(value, name, props) => {
                  return MONTH_SHORTEN[value];
                }}
              />
            </LineChart>
          </ResponsiveContainer>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Jan',
      width: '3%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[0];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Feb',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[1];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Mar',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[2];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Apr',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[3];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'May',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[4];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Jun',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[5];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Jul',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[6];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Aug',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[7];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Sep',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[8];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Oct',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[9];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Nov',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[10];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: 'Dec',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ monthly_eui }) => {
        const data = R.values(monthly_eui)[11];
        const { value, status } =
          data instanceof Object
            ? data
            : {
                value: data,
                status: true,
              };
        return (
          <div
            className={status ? '' : styles.nonComplete}
            style={{ padding: '5px', lineHeight: '1.5rem' }}
          >
            {formatNumber(value || 0, 1)}
          </div>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
  ];

  const processData = (siteData) => {
    return R.compose(
      R.values,
      R.mapObjIndexed((item, key, obj) => {
        return {
          siteId: key,
          ...item,
        };
      })
    )(siteData);
  };

  const data = mapValues(processData(site_eui), (item) => item);

  const groupHeaders: TableGroupHeader[] = [
    {
      header: '',
      colSpan: 1,
    },
    {
      header: 'EUI (kWh/m²)',
      colSpan: 15,
    },
  ];

  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(data, [['site_title']]);

  const displayData = filterSearchList.filter((site) => {
    if (selectedSiteFilters.length > 0) {
      return selectedSiteFilters.includes(Number(site.siteId));
    } else {
      return true;
    }
  });

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    data?.length
  );

  return (
    <SeeAllPage
      title="Summary"
      displayCountText="sites"
      headerName="Annual Performance"
      backRoute={Routes.SUMMARY}
      searchProps={{
        query,
        onChange: setQuery,
        error: filterSearchList.length === 0,
      }}
      tabs={siteFilter}
      filters={tableLinks}
      // alternativeContent={
      //   !isLoading && !isThereData ? <HappyHornMessage /> : undefined
      // }
      // disableDateFilter
      customDateFilter={
        <DateFilter
          value={selectedFilterOption}
          selectedYear={selectedYear}
          setSelectedYear={updateYearParam}
          setFilter={updateSelectedFilterOption}
          optionCreator={() => []}
          disableCustomRange={true}
          disableBefore={DA_REPORT_AVAILABLE_DATE}
          disableAfter={disableAfterOption}
          displayDatesAsTitle
          customPickerVariant="yearly"
          alignItemsRight={true}
          locale={locale}
        />
      }
      summaryStatistics={[
        {
          value: org_eui_existing || '-',
          numDecimals: 1,
          unit: 'kWh/m²',
          title: 'EUI (YTD)',
          bold: true,
        },
        {
          value: org_eui_est || '-',
          numDecimals: 1,
          unit: 'kWh/m²',
          title: 'Estimated EUI',
          bold: true,
        },
      ]}
      tableProps={{
        groupHeaders,
        columns,
        data: displayData || [],
        dataIsLoading: isLoading,
        listView: true,
        rowLimitFromPaginationHook: rowLimit,
        currentPageFromPaginationHook: currentPage,
        setPagination: setPagination,
      }}
    ></SeeAllPage>
  );
};

const tableLinks = [
  <Link to={Routes.ANNUAL_ENERGY_CONSUMPTION}>
    <Button
      size="small"
      secondary
      style={{ border: '1px solid', borderRadius: '5px' }}
    >
      {'Energy Consumption'}
    </Button>
  </Link>,

  <Link to={Routes.ANNUAL_USAGE_INTENSITY}>
    <Button size="small" style={{ border: '1px solid', borderRadius: '5px' }}>
      {'EUI'}
    </Button>
  </Link>,

  <Link to={Routes.ANNUAL_CO2_EMISSION}>
    <Button
      size="small"
      secondary
      style={{ border: '1px solid', borderRadius: '5px' }}
    >
      {'CO₂ Emissions'}
    </Button>
  </Link>,
];

const siteFilter = [<SiteFilter />];

export default AnnualUsageIntensity;
